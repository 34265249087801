var cartWidget;

$(document).ready(function(){

    cartWidget = $(".cart-widget");



    $('body').on("click", ".cart-widget .increase-qty", function() {
        var $qtyInput = $(this).parent().find('.qty-input');
        var qty = $qtyInput.val();
        qty++; // increase quantity by one
        var sku = $qtyInput.data().sku;
        var url = $qtyInput.data().url;
        var title = $qtyInput.data().productitle;
        var price = $qtyInput.data().price;
        var category = $qtyInput.data().category;
        var subcategory = $qtyInput.data().subcategory;
        var currencyCode = $qtyInput.data().currencyCode;
        var brand = $qtyInput.data().brand;
        var product = $qtyInput.data().productname;
        var key = $qtyInput.data().key;
        var make = $qtyInput.data().make;
        var model = $qtyInput.data().model;
        var year = $qtyInput.data().year;
        var vehicleText = $qtyInput.data().vehicle;
        var vehiclePosition = $qtyInput.attr('data-vehicle-position');

        var metadata = {make : make, model : model, year: year, vehicleText : vehicleText, vehiclePosition : vehiclePosition};
        var data = { sku : sku, qty : qty, name : product, category : category, brand : brand, title : title, price : price, metadata : metadata,  subcategory: subcategory,
            currencyCode: currencyCode};

        // increase by one
        $qtyInput.val(qty)

        trackAddToCart(data);
        viewCart(url, data);

    });

    $('body').on("click", ".cart-widget .decrease-qty", function() {
        var $qtyInput = $(this).parent().find('.qty-input');
        var qty = $qtyInput.val();
        qty--; // decrease quantity by one
        var sku = $qtyInput.data().sku;
        var url = $qtyInput.data().url;
        var title = $qtyInput.data().productitle;
        var price = $qtyInput.data().price;
        var category = $qtyInput.data().category;
        var subcategory = $qtyInput.data().subcategory;
        var currencyCode = $qtyInput.data().currencyCode;
        var brand = $qtyInput.data().brand;
        var product = $qtyInput.data().productname;
        var key = $qtyInput.data().key;
        var make = $qtyInput.data().make;
        var model = $qtyInput.data().model;
        var year = $qtyInput.data().year;
        var vehicleText = $qtyInput.data().vehicle;
        var vehiclePosition = $qtyInput.attr('data-vehicle-position');

        var metadata = {make : make, model : model, year: year, vehicleText : vehicleText, vehiclePosition : vehiclePosition};
        var data = { sku : sku, qty : qty, name : product, category : category, brand : brand, title : title, price : price, metadata : metadata,  subcategory: subcategory,
            currencyCode: currencyCode};

        // if we have qty of 0, delete the product
        if (qty === 0) {
            url = $qtyInput.data().urlremove;
            trackRemoveFromCart(data);
            viewCart(url, data);
        } else {
            // decrease by one
            $qtyInput.val(qty)
            trackRemoveFromCart(data);
            viewCart(url, data);
        }
    });

    // when the update button is clicked, update the quantity
    $('body').on("change", ".cart-widget .qty", function() {

        var qty = $(this).val();

        // if empty, do nothing
        if (qty.trim().length == 0) return;

        var sku = $(this).data().sku;
        var url = $(this).data().url;

        var title = $(this).data().productitle;
        var price = $(this).data().price;
        var category = $(this).data().category;
        var subcategory = $(this).data().subcategory;
        var currencyCode = $(this).data().currencyCode;
        var brand = $(this).data().brand;
        var product = $(this).data().productname;
        var key = $(this).data().key;


        var make = $(this).data().make;
        var model = $(this).data().model;
        var year = $(this).data().year;
        var vehicleText = $(this).data().vehicle;
        var vehiclePosition = $(this).attr('data-vehicle-position');

        var metadata = {make : make, model : model, year: year, vehicleText : vehicleText, vehiclePosition : vehiclePosition};

        if (isNaN(qty) || !isInt(qty) || qty <=0) {
            alert("Please enter a valid quantity between 1 and 99");
            return false;
        }

        var data = { sku : sku, qty : qty, name : product, category : category, brand : brand, title : title, price : price, metadata : metadata,  subcategory: subcategory,
            currencyCode: currencyCode};
        trackRemoveFromCart(data);
        viewCart(url, data);
    });



    // when the shipping insurance is toggled
    $('body').on("change", ".cart-widget #shipping-insurance", function() {
        var shippingInsurance = false;
        if ($('input#shipping-insurance').is(':checked')) {
            shippingInsurance = true;
        }

        var data = { shipping_insurance : shippingInsurance};
        viewCart(cartWidget.data().url, data);
    });


    // hide/show the cart widget overlay when the cart icon is clicked
    $(".cart-trigger button").on("click", function() {
        if (cartWidget.is(":visible")) {
            cartWidget.slideToggle();
        } else {
            viewCart(cartWidget.data().url);
        }
    });

    // when the remove/trash can button is clicked
    $('body').on("click", ".cart-widget .remove-item", function() {
        var sku = $(this).data().sku;
        var url = $(this).data().url;

        var qty = $(this).data().qty;
        var title = $(this).data().productitle;
        var price = $(this).data().price;
        var category = $(this).data().category;
        var subcategory = $(this).data().subcategory;
        var currencyCode = $(this).data().currencyCode;
        var brand = $(this).data().brand;
        var product = $(this).data().productname;

        var make = $(this).data().make;
        var model = $(this).data().model;
        var year = $(this).data().year;
        var vehicleText = $(this).data().vehicle;
        var vehiclePosition = $(this).attr('data-vehicle-position');

        var metadata = {make : make, model : model, year: year, vehicleText : vehicleText, vehiclePosition : vehiclePosition};

        var data = { sku : sku, qty : qty, name : product, category : category, brand : brand, title : title, price : price, metadata : metadata,  subcategory: subcategory,
            currencyCode: currencyCode};

        trackRemoveFromCart(data);
        viewCart(url, data);
    });

    $('body').on("click", ".cart-widget #copy-cart-url", function() {

        // Get the text field
        var hiddenInput = document.getElementById("cart-quote-url");

        // create a non-hidden text field off the canvas
        var copyText = document.createElement("input");
        copyText.style = "position: absolute; left: -1000px; top: -1000px";
        copyText.value = hiddenInput.value;
        document.body.appendChild(copyText);

        // Select the text field
        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices

        // Copy the text inside the text field
        navigator.clipboard.writeText(copyText.value);

        // remove the non-hidden text field
        document.body.removeChild(copyText);

        // Alert the copied text
        alert("Copied the text: " + copyText.value);
    });


    $('body').on("click", ".cart-widget .coupon-code-btn", function() {

        var couponCode = $(".cart-widget .coupon-code").val();
        var url = $(this).data().url;
        var data = {coupon_code: couponCode};

        viewCart(url, data);
    });

    $('body').on("click", ".cart-widget #save-cart", function() {

        var url = $(this).data().url;
        viewCart(url, {});
    });

    $('body').on("click", ".cart-widget #close-saved-cart", function() {

        var url = $(this).data().url;
        viewCart(url, {});
    });

    $('body').on("click", ".cart-widget #retrieve-saved-cart", function() {

        var cartId = $(".cart-widget .cart_quote_id").val();

        // if cart id is empty, show alert
        if (cartId.trim().length == 0) {
            alert("Please enter a valid cart id");
            return false;
        }

        var url = $(this).data().url;
        var data = {cart_quote_id: cartId};

        viewCart(url, data);
    });


    $('body').on("click", ".cart-widget .zipcode-lookup", function() {

        var country = $(".zipcode").data().country;
        var zipcode = $(".cart-widget .zipcode").val();

        if (country == 'US') {
            if (!(/^\s*\d{5}\s*$/.test(zipcode))) {
                alert("Please Enter a 5-digit Zipcode");
                return false;
            }
        } else if (country == 'CA') {
            if (!( /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i.test(zipcode))) {
                alert("Please Enter a Valid Canadian Zipcode");
                return false;
            }
        }

        var url = $(this).data().url;
        var data = {zipcode: zipcode};

        viewCart(url, data);
    });

    $("body").on("click", ".shipping-method", function() {
        var cost = $(this).attr("data-cost");
        var total = $(this).attr("data-total");
        var method = $(this).closest('.shipping-method-tr').data().method;


        $(".ground-shipping").html(cost);


        $(".total-price-formatted").html(total);

    });

    $("body").on("click", ".shipping-method-tr", function(event) {
        if (!$(event.target).is('.shipping-method')) {
            $(this).find("input").click();
        }
    });

    $("body").on("click", ".checkout-button", function(event) {
        $(this).attr("disabled", "disabled");
        $(this).css("background-image", "none");
        $(this).css("background-color", "#efefef");
        $(this).css("border", "1px solid #fff");
        $(this).css("color", "#333");
        $(this).text("Please Wait...");
    });


});

global.viewCart = function viewCart(url, data) {
    $.LoadingOverlay("show");
    $.ajax({
        url: url,
        type: "get",
        data: data,
        cache: false,
        dataType: "html",
        success: function (data) {
            if (data == "false") {
                window.location.href = URL_SHOPPING_CART;
            } else {
                cartWidget.html(data);
                //reload custom dropdowns
                dropdown();
                $.LoadingOverlay("hide");
            }
        }
    });

}
/* general functions */

function setselectText($element, text) {
    $element.text(text)
}

// save an attribute to the document body for later retrieval
function putAttribute(key, value) {
    jQuery.data(document.body, key, value);
}

//  retrieve a previously stored attribute from the document body
function getAttribute(key) {
    return jQuery.data(document.body, key);
}

function issetAttribute(key) {
    if (jQuery.data(document.body, key) === undefined) {
        return false;
    }

    return true;
}

/* end general functions */

// set vehicle widget back to it's default state
function clearVehicleWidget() {

    $(".vehicle-selector .make-cont select").removeClass('active');
    $(".vehicle-selector .model-cont select").removeClass('active');
    $(".vehicle-selector .submodel-cont select").removeClass('active');
    $(".vehicle-selector .engine-cont select").removeClass('active');
    $(".vehicle-selector .submit-cont button").removeClass('active')

    // set select text back to default states
    $('.vehicle-selector .year-cont select').removeAttr("selected");
    $('.vehicle-selector .make-cont select').removeAttr("selected");
    $('.vehicle-selector .model-cont select').removeAttr("selected");
}

function setActionText(elem) {
    setselectText($(".vehicle-widget .action"), elem.data().desc);
}

$(document).ready(function () {

    loadYearsDecade($(".year-cont select").attr("data-url"), $(".vehicle-selector .year-cont select"), false);


    function loadVehicleDropdown(container) {

        //var getUrl = window.location;
        //var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
        var url = "/vehicle-widget?force-dropdown=true";

        // if container is null, set teh container to .dropdown-cont class
        if (container == null) {
            container = $(".dropdown-cont");
        }

        // if .dropdown-cont is present on the page
        if (container.length > 0) {

            // make ajax request to get the vehicle dropdown and replace the dropdown-cont with the new dropdown
            $.ajax({
                url: url,
                type: "get",
                cache: false
            }).done(function (data) {

                container.replaceWith(data);
                loadYearsDecade($(".year-cont select").attr("data-url"), $(".vehicle-selector .year-cont select"), false);
                /*// replace the div dropdown-cont with data from the ajax request
                const oldContainer = document.querySelector(".dropdown-cont");
                const dropdownCont = document.createElement(data);
                oldContainer.parentNode.replaceChild(dropdownCont, oldContainer);*/
            });
        }

    }


    $(".all-vehicles").on("click", function (event) {
        if (event.target === this) {
            $(".other-vehicles").toggle();
            /*
            if ($(this).css('color') == 'rgb(172, 0, 2)') {
                $(this).css('color', '#fff');
            } else {
                $(this).css('color', '#AC0002');
            }*/
        }
    });

    $(".all-vehicles > span").on("click", function (event) {
        if (event.target === this) {
            $(".other-vehicles").toggle();
            /*
            if ($(".all-vehicles").css('color') == 'rgb(172, 0, 2)') {
                $(".all-vehicles").css('color', '#fff');
            } else {
                $(".all-vehicles").css('color', '#AC0002');
            }
            */

        }
    });


    $('body').on("click", '[data-js="add-vehicle"]', function () {
        $('#vehicle-select').removeClass('-selected');
    });


    $(".dropdown-cont-vertical .close").on("click", function () {
        hideModalBackground();
        $(".dropdown-cont-vertical").hide();
    });

    $(".add-new-vehicle").on("click", function () {

        // show vertical dropdown if exists on page
        if ($(".dropdown-cont-vertical").length > 0) {
            $(".dropdown-cont-vertical").prependTo(".part-finder");
            //$(".dropdown-cont-vertical").css("left", "-50px");
            //$(".dropdown-cont-vertical").css("top", "20px");
            $(".dropdown-cont-vertical").toggle();
            // else trigger an existing dropdown if present
        } else {
            $(".year-cont select").click();
        }
    });

    $(".show-change-vehicle").on("click", function () {

        if ($(".vehicle-widget").length > 2) {
            $(".year-cont select").trigger("click");
            return;
        }
        showModalBackground();

        $(".dropdown-cont-vertical").appendTo(".change-vehicle-cont");
        $(".dropdown-cont-vertical").css("left", "0px");
        $(".dropdown-cont-vertical").css("top", "20px");
        $(".dropdown-cont-vertical").toggle();
    });

    /*    $(".remove-saved-vehicle").on("click", function() {

            //   showLoading();
            var url = $(this).data('remove');
            var node = $(this);
            var refreshPage = $(this).hasClass("refresh-page");

            //node.parents(".current-vehicle").remove();
            var params = {uri : $(location).attr('href')};

            $.ajax({
                url: url,
                type: "get",
                cache: false,
                data: params,
                dataType: "json",
                success: function (result) {
                    if (refreshPage) {
                        //       clearLoading()
                        var getUrl = window.location;
                        var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
                        //window.location.href = baseUrl + "?" + result.queryString;
                        //window.location.href = getUrl;
                        location.reload();
                        //window.location.href = $(location).attr('href');
                    } else {
                        loadVehicleDropdown();
                    }
                }
            });

        });*/

    $('body').on("click", ".add-vehicle", function () {

        containerClass = $(this).data("container");
        container = $("." + containerClass);
        loadVehicleDropdown(container);
        /*
        $(".choose-notification").show();
        // register event to hide the notification after 3 seconds
        setTimeout(function () {
            $(".choose-notification").hide();
        }, 3000);

         */
    });
    $('body').on("click", ".add-vehicle-simple", function () {

        // using jquery, create a fade affect, that adds the following hightlight class to the container, .vehicle-search, and then fades it away after 1 second
        $(".highlight-search").fadeTo(1000, .5, 'linear' , function () {
            $(".highlight-search").fadeOut(1000, 'linear');
        });

        if ($(this).hasClass("ppc-page")) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $(".highlight-search").offset().top
            }, 0);
        }


/*

        // create a highlighting affect around the vehicle search container that fades in, holds for 3 seconds, and fades out
        //$(".vehicle-search").addClass("highlight");
        setTimeout(function () {
            $(".highlight-search").fadeOut(1000, 'linear');

        }     , 1000);
*/



       /* $(".choose-notification").show();
        // register event to hide the notification after 3 seconds
        setTimeout(function () {
            $(".choose-notification").hide();
        }, 3000);*/
    });

    $('body').on("click", ".highlight-narrow-vehicle", function () {

        // using jquery, create a fade affect, that adds the following hightlight class to the container, .vehicle-search, and then fades it away after 1 second
        $(".facet-highlight").fadeTo(1000, .5, 'linear' , function () {
            $(".facet-highlight").fadeOut(1000, 'linear');
        });
    });

    $('body').on("click", ".remove-saved-vehicle", function () {
        //$(".remove-saved-vehicle").on("click", function() {

        //   showLoading();
        var url = $(this).data('remove');
        var node = $(this);
        var refreshPage = $(this).hasClass("refresh-page");

        node.parents(".current-vehicle").remove();
        var params = {uri: $(location).attr('href')};

        $.ajax({
            url: url,
            type: "get",
            cache: false,
            data: params,
            dataType: "json",
            success: function (result) {
                if (refreshPage) {
                    //       clearLoading()
                    var getUrl = window.location;
                    // if we are on the parts page, then go to the homepage
                    if (window.location.pathname.split('/')[1] === "parts") {
                        window.location.href = getUrl.protocol + "//" + getUrl.host + "/"
                        // if we are on the parts page, then go to the homepage
                    } else if (window.location.pathname.split('/')[1] === "products") {

                        var categoryAttr = $(".vehicle-selector-dropdown").attr('data-category');
                        var partAttr = $(".vehicle-selector-dropdown").attr('data-part');
                        var nonVehicleUri = $(".vehicle-selector-dropdown").attr('data-non-vehicle-uri');

                        // if nonVehicleUri exists, and is not empty, then construct the url using the nonVehicleUri
                        if (nonVehicleUri && nonVehicleUri.length > 0) {
                            window.location.href = getUrl.protocol + "//" + getUrl.host + "/products" + nonVehicleUri;
                        }
                        // else if partAttr exists, and is not empty, then construct the url using the partAttr
                        else if (partAttr && partAttr.length > 0) {
                            uriString = "?base-attributes[part_name_description]=" + partAttr;
                            // un-urlencode the partAttr string
                            partName = decodeURIComponent(partAttr);
                            // remove plus signs from the partAttr string and replace with hyphens
                            partName = partName.replace(/\+/g, '-');

                            window.location.href = getUrl.protocol + "//" + getUrl.host + "/products/" + partName + uriString;
                            // if neither a URI string nor partAttribute exists, then just take the last part of the url, which should be the search string, and use that
                        } else {
                            // get the last item in the URL, which should be the part name, and search for it
                            var items = getUrl.pathname.split('/');
                            window.location.href = getUrl.protocol + "//" + getUrl.host + "/products/" + items[items.length - 1];
                        }

                    } else {

                        var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
                        //window.location.href = baseUrl + "?" + result.queryString;
                        //window.location.href = getUrl;
                        location.reload();
                        //window.location.href = $(location).attr('href');
                    }
                } else {
                    loadVehicleDropdown();
                }
            }
        });

    });


    // when the year select is clicked, show the years
    $(".year-cont select").on("click", function () {
        var url = $(this).attr("data-url");

        $(".vehicle-selector .year-cont .overlay").show();

        $(".vehicle-selector .year-cont .menu-down").addClass('active');
        $(".vehicle-selector .make-cont .menu-down").removeClass('active');
        $(".vehicle-selector .model-cont .menu-down").removeClass('active');

        setActionText($(".vehicle-selector .year-cont select"))

    });

    // when the make select is clicked, show the makes. No need to load them as they should have been loaded when the year was clicked on
    $(".make-cont select").on("click", function () {
        $(".vehicle-selector .make-cont .overlay").show();

        $(".vehicle-selector .year-cont .menu-down").removeClass('active');
        $(".vehicle-selector .make-cont .menu-down").addClass('active');
        $(".vehicle-selector .model-cont .menu-down").removeClass('active');

        setActionText($(".vehicle-selector .make-cont select"))
    });

    // when the model select is clicked, show the models. No need to load them as they should have been loaded when the make was clicked on
    $(".model-cont select").on("click", function () {
        $(".vehicle-selector .model-cont .overlay").show();

        $(".vehicle-selector .year-cont .menu-down").removeClass('active');
        $(".vehicle-selector .make-cont .menu-down").removeClass('active');
        $(".vehicle-selector .model-cont .menu-down").addClass('active');

        setActionText($(".vehicle-selector .model-cont select"))
    });


    // Filter Results select -- load the parts
    $(".vehicle-selector .submit-cont button").on("click", function () {

        alert("You must select a Year, Make, and Model to continue");

    });


    // when a year is picked from the overlay
    $('body').on("change", ".vehicle-selector .year-cont select", function () {
        //$(".vehicle-selector").on("change", ".year-cont select", function() {
        var id = $(this).val();

        if (id != '') {

            // store id
            $(".vehicle-selector .year-cont select").data("id", id);
            putAttribute('year', id);
            var url = $(".vehicle-selector .make-cont select").attr("data-url");

            trackVehicleSelection("year", id);
            loadMakes(url, id, $(".vehicle-selector .year-cont select"), $(".vehicle-selector .make-cont select"));

            // toggle select active states
            $(".vehicle-selector .make-cont select").addClass('active');
            $(".vehicle-selector .year-cont .menu-down").removeClass('active');
            $(".vehicle-selector .make-cont .menu-down").addClass('active');
            $(".vehicle-selector .model-cont select").removeClass('active');
            $(".vehicle-selector .model-cont .menu-down").removeClass('active');
            $(".vehicle-selector .submit-cont button").removeClass('active')

            // set select text back to default states
            setselectText($(".vehicle-selector .make-cont select .label"), $(".vehicle-selector .make-cont select").data().label);
            setselectText($(".vehicle-selector .model-cont select .label"), $(".vehicle-selector .model-cont select").data().label);

            setActionText($(".vehicle-selector .make-cont select"));
        }
    });

    // when a make is picked from the overlay
    $('body').on("change", ".vehicle-selector .make-cont select", function () {
        //$(".vehicle-selector").on("change", ".make-cont select", function() {
        var id = $(this).val();

        if (id.trim().length > 0) {
            var make = $(".vehicle-selector .make-cont select option:selected").text();
            var year = $(".vehicle-selector .year-cont select").data("id");

            // store id
            $(".vehicle-selector .make-cont select").data("id", make);
            putAttribute('make', make);
            var url = $(".vehicle-selector .model-cont select").attr("data-url");

            trackVehicleSelection("make", make);
            loadModels(url, year, s_encode(make), $(".vehicle-selector .make-cont select"), $(".vehicle-selector .model-cont select"))

            // toggle select active states
            $(".vehicle-selector .model-cont select").addClass('active');
            $(".vehicle-selector .year-cont .menu-down").removeClass('active');
            $(".vehicle-selector .make-cont .menu-down").removeClass('active');
            $(".vehicle-selector .model-cont .menu-down").addClass('active');

            $(".vehicle-selector .submit-cont button").removeClass('active')

            // set select text back to default states
            setselectText($(".vehicle-selector .model-cont select .label"), $(".vehicle-selector .model-cont select").data().label);
            setActionText($(".vehicle-selector .model-cont select"))
        }

    });

    // when a model is picked from the overlay
    $('body').on("change", ".vehicle-selector .model-cont select", function () {
        //$(".vehicle-selector").on("change", ".model-cont select", function() {
        var modelId = $(this).val();

        if (modelId.trim().length > 0) {

            var model = $(".vehicle-selector .model-cont select option:selected").text();
            var year = $(".vehicle-selector .year-cont select").data("id");
            var make = $(".vehicle-selector .make-cont select").data("id");

            // store id
            $(".vehicle-selector .model-cont select").data("id", model);
            putAttribute('model', model);
            var url = $(".vehicle-selector .submodel-cont select").attr("data-url");

            var target = $(".vehicle-widget").data().target;
            target = target.replace("{year}", s_encode(year));
            target = target.replace("{make}", s_encode(make));
            target = target.replace("{model}", s_encode(model));

            var categoryAttr = $(".vehicle-selector-dropdown").attr('data-category');
            var partAttr = $(".vehicle-selector-dropdown").attr('data-part');


            // go to the popular parts tab, and show the category at the top
            if (typeof categoryAttr !== typeof undefined && categoryAttr !== false) {
                target += "?category=" + categoryAttr;
                if (typeof partAttr !== typeof undefined && partAttr !== false) {
                    target += "&part=" + partAttr;
                }

            }


            // set the Filter Results select to active
            $(".vehicle-selector .submit-cont button").attr('disabled', 'disabled');
            $(".vehicle-selector .submit-cont button").addClass('disabled')
            $(".vehicle-selector .submit-cont button").html('Please Wait...');


            trackVehicleSelection("model", model);
            trackVehicleDropdown(year, make, model);
            saveVehicle(year, make, model, target);

        }
    });


    function loadYearsDecade(url, yearSelector, showOverlay) {

        if (url === undefined) {
            return;
        }

        $.getJSON(url, function (data) {

            for (var i = -1, n = data.length; ++i < n;) {
                var record = data[i];

                jQuery('<option/>', {
                    value: record.year,
                    html: record.year
                }).appendTo(yearSelector); //appends to select if parent div has id dropdown
            }
        });

    }


    function loadMakes(url, year, yearSelector, makeSelector) {

        $.getJSON(url + "/" + year, function (data) {
            makeSelector.find('option').not(':first').remove();

            for (var i = -1, n = data.length; ++i < n;) {
                var record = data[i];

                jQuery('<option/>', {
                    value: record.a,
                    html: record.b
                }).appendTo(makeSelector); //appends to select if parent div has id dropdown
            }

            makeSelector.trigger('click');

        });

    }

    function loadModels(url, year, make, makeSelector, modelSelector) {

        $.getJSON(url + "/" + year + "/" + encodeURIComponent(make), function (data) {
            modelSelector.find('option').not(':first').remove();

            for (var i = -1, n = data.length; ++i < n;) {
                var record = data[i];

                jQuery('<option/>', {
                    value: record.a,
                    html: record.b
                }).appendTo(modelSelector); //appends to select if parent div has id dropdown
            }

            modelSelector.trigger('click');
        });
    }

    function loadSubModels(url, year, make, model, modelSelector, subModelSelector) {

        $.getJSON(url + "/" + year + "/" + encodeURIComponent(make) + "/" + encodeURIComponent(model), function (data) {
            var h = -1;
            var html = [];
            html[h++] = '';

            count = data.length / 3;
            counter = 0;

            html[++h] = "<ul>";

            for (var i = -1, n = data.length; ++i < n;) {
                counter++;

                var item = data[i];

                html[++h] = "<li data-id=\"";
                html[++h] = item.a;
                html[++h] = "\" class=\"make\">";
                html[++h] = item.b;
                html[++h] = "</li>";

                if (counter > count) {
                    counter = 0;
                    html[++h] = "</ul>";
                    html[++h] = "<ul>";
                }
            }

            html[h++] = "</ul>";

            subModelSelector.html(html.join(''));
            modelSelector.hide();
            subModelSelector.show();


        });
    }

    function loadEngines(url, year, make, model, submodel, subModelSelector, engineSelector) {

        $.getJSON(url + "/" + year + "/" + encodeURIComponent(make) + "/" + encodeURIComponent(model) + "/" + encodeURIComponent(submodel), function (data) {
            var h = -1;
            var html = [];
            html[h++] = '';

            count = data.length / 3;
            counter = 0;

            html[++h] = "<ul>";

            for (var i = -1, n = data.length; ++i < n;) {
                counter++;

                var item = data[i];

                html[++h] = "<li data-id=\"";
                html[++h] = item.a;
                html[++h] = "\" class=\"make\">";
                html[++h] = item.b;
                html[++h] = "</li>";

                if (counter > count) {
                    counter = 0;
                    html[++h] = "</ul>";
                    html[++h] = "<ul>";
                }
            }

            html[h++] = "</ul>";

            engineSelector.html(html.join(''));
            subModelSelector.hide();
            engineSelector.show();


        });
    }


    // hide dynamic menu's when you click outside
    $(document).mouseup(function (e) {
        var container = $(".vehicle-selector .overlay");

        if (!container.is(e.target) // if the target of the click isn't the container...
            && container.has(e.target).length === 0) // ... nor a descendant of the container
        {
            container.fadeOut(300);
        }

    });


});


global.URL_SHOPPING_CART = URL_SHOPPING_CART = '/cart'
global.urlProtoHost = window.location.protocol + "//" + window.location.host;

// this is for the text fading
var $el, $ps, $up, totalHeight;

function openSubmenu() {
    $('.main-menu').show();
    $(".modal-background").addClass("is-active");
}

function closeSubmenu() {
    $('.main-menu').hide();
    $(".modal-background").removeClass("is-active");
}

var xhr;
var _orgAjax = jQuery.ajaxSettings.xhr;
jQuery.ajaxSettings.xhr = function () {
    xhr = _orgAjax();
    return xhr;
};

$(document).ready(function () {

    dropdown();

    /*
    // stick vehicle selector to top of screen when scrolling
    if ($(".main-search").length > 0) {
        var t = $(".main-search");
        var post = t.position();
        $(window).scroll(function () {
            var windowpos = $(window).scrollTop();

            if (windowpos >= post.top) {
                t.addClass("stick");
                $(".page").addClass('stick-offset');
                //$(".page-header").hide();
            } else {
                t.removeClass("stick");
                $(".page").removeClass('stick-offset');
               // $(".page-header").show();
            }
        });
    }
    */


    $("#toggle-universal-products").on('change', function () {
        if ($(this).is(":checked")) {
            document.cookie = "universal=1; path=/";
        } else {
            document.cookie = "universal=0; path=/";
        }
        location.reload();
    })

    $(".allow-universal-link").on('click', function () {
        $("#toggle-universal-products").prop("checked", true);
        document.cookie = "universal=1; path=/";
        location.reload();
    })

    $('body').on("click", ".sub-menu-toggle", function (e) {
        var senderElement = e.target;
        if (!$(senderElement).hasClass("dropdown-item-back")) {
            var id = $(this).attr('id');
            var submenu = $('[aria-labelledby="' + id + '"]');
            submenu.show();
            event.stopPropagation(); // stop the menu from hiding itself
        }
    });

    $('body').on("click", ".dropdown-item-back", function () {
        event.stopPropagation(); // stop the menu from hiding itself
        var id = $(this).attr('data-id');
        var submenu = $('[aria-labelledby="' + id + '"]');
        console.log(id);
        console.log(submenu);
        submenu.hide();
        event.stopPropagation();

    });


    $('body').on("click", ".additional-fitment", function () {
        $(".product-attributes-pane").removeClass("active");
        $(".product-attributes-pane").removeClass("show");
        $(".fitment-pane").addClass("active");
        $(".fitment-pane").addClass("show");

        // add remove active button
        $(".additional-fitment").addClass("active");
        $(".product-attributes").removeClass("active");
    });

    $('body').on("click", ".product-attributes", function () {
        $(".fitment-pane").removeClass("active");
        $(".fitment-pane").removeClass("show");
        $(".product-attributes-pane").addClass("active");
        $(".product-attributes-pane").addClass("show");

        // add remove active button
        $(".product-attributes").addClass("active");
        $(".additional-fitment").removeClass("active");
    });


    // stick vehicle selector to top of screen when scrolling
    if ($(".dropdown-cont").length > 0) {
        var s = $(".dropdown-cont");
        var pos = s.position();
        $(window).scroll(function () {
            var windowpos = $(window).scrollTop();

            if (windowpos >= pos.top) {
                s.addClass("stick");
                s.addClass("vehicle-offset");
                //s.find(".main-search").show();
                //$('.main-search').addClass('-has-focus');
                $('.main-search').addClass('stick');
                $(".page").addClass('stick-offset');
                // $(".page-header").hide();
            } else {
                if (s.hasClass("stick")) {
                    s.removeClass("stick");
                    //s.find(".main-search").hide();
                    //$('.main-search').removeClass('-has-focus');
                    $('.main-search').removeClass('stick');
                    $(".page").removeClass('stick-offset');
                    //$(".page-header").show();
                }
            }
        });

        changeDropdownText();
        // when window changes from mobile to desktop
        $(window).resize(function () {
            changeDropdownText();
        });



    }

    function changeDropdownText() {
        if ($(window).width() < 768) {
            $('.year-cont select option:first-child').text("Year");
            $('.make-cont select option:first-child').text("Make");
            $('.model-cont select option:first-child').text("Model");
        } else {
            $('.year-cont select option:first-child').text("Select Year");
            $('.make-cont select option:first-child').text("Select Make");
            $('.model-cont select option:first-child').text("Select Model");
        }
    }

    if ($("img").length > 0) {
        $("img").unveil();
    }

    $('body').on("click", ".add-to-wishlist", function () {

        var $this = $(this);

        var sku = $this.attr("data-sku");
        var application = $this.attr("data-application");
        var url = $this.attr("data-url");
        var make = $this.attr("data-make");
        var model = $this.attr("data-model");
        var year = $this.attr("data-year");
        var vehicleText = $this.attr("data-vehicle");
        var vehiclePosition = $this.attr('data-vehicle-position');

        var metadata = {
            make: make,
            model: model,
            year: year,
            vehicleText: vehicleText,
            vehiclePosition: vehiclePosition
        };
        var data = {ps_sku: sku, application_id: application, metadata: metadata};

        $.ajax({
            url: url,
            type: "get",
            data: data,
            cache: false,
            success: function (responseText) {
                if (responseText != '') {
                    window.location.href = xhr.responseURL + '?a=1&ref=' + window.location.pathname;
                }
                if ($this.text().trim() == 'Add to Favorites') {
                    $this.text(' Remove from Favorites')
                } else {
                    $this.text(' Add to Favorites')
                }
                //console.log('responseURL:', xhr.responseURL, 'responseText:', responseText);
            }
        });

        // toggle class when heart is checked, as long as we are not on the wishlist screen, where an X (fa-times) is displayed
        if (!$(this).hasClass('viewing-wishlist')) {
            //$(this).toggleClass('delete-from-wishlist');
            //$(this).toggleClass('add-to-wishlist');
            var deleteUrl = $(this).attr('data-url-delete');
            var addUrl = $(this).attr('data-url-add');
            $(this).attr('data-url', $(this).attr('data-url') == deleteUrl ? addUrl : deleteUrl)
        }

        return false;
    });


    $('body').on("click", ".delete-from-wishlist", function () {
        var $this = $(this);

        var sku = $this.attr("data-sku");
        var url = $this.attr("data-url");
        var data = {ps_sku: sku};

        $.ajax({
            url: url,
            type: "get",
            data: data,
            cache: false,
            success: function (responseText) {
                if (responseText != '') {
                    window.location.href = xhr.responseURL + '?a=1&ref=' + window.location.pathname;
                }
                $this.closest('.product-line').remove();
            }
        });

    });


    var timer;

    /*
    $('.has-dropdown').on("mouseover", function() {
        clearTimeout(timer);
        openSubmenu();
    }).on("mouseleave", function() {
        timer = setTimeout(
            closeSubmenu
            , 200);
    });
*/

    // show then hide dropdown menu on hover
    $('.has-dropdown').hover(function () {
        $('.main-menu').delay(200).show();
        //$(".modal-background").delay(200).show();
    }, function () {
        $('.main-menu').stop(true).delay(200).hide();
        $("#category-dropdown").removeClass("is-active");
        //$(".modal-background").stop(true).delay(200).hide();
    });


    $('.category-menu-item').hover(function () {
        $(this).addClass("is-active");
        var hash = $(this).attr('data-hash');

        $(".menu-groups").hide();
        $("#c-" + hash).css('display', 'flex');

    }, function () {
        $(this).removeClass("is-active");
    });

    $('.navbar-link').hover(function () {
        $(this).addClass("is-active");
    }, function () {
        if (this.id != 'category-dropdown') {
            $(this).removeClass("is-active");
        }
    });
    /*$('#burger').on('click', function () {
        $('#mobile-nav').addClass('mobile-nav_active');
    });
    $('#mobile-nav').on('click', '.mobile-nav__close', function () {
        $('#mobile-nav').removeClass('mobile-nav_active');
    });*/


    $(".show-loading").click(function () {
        showLoading();
    });


    // this is for the text fading
    $(".sidebar-box .button").click(function () {

        totalHeight = 0

        $el = $(this);
        $p = $el.parent();
        $up = $p.parent();
        $ps = $up.find("p:not('.read-more')");

        // measure how tall inside should be by adding together heights of all inside paragraphs (except read-more paragraph)
        $ps.each(function () {
            totalHeight += $(this).outerHeight();
        });

        $up
            .css({
                // Set height to prevent instant jumpdown when max height is removed
                "height": $up.height(),
                "max-height": 9999
            })
            .animate({
                "height": totalHeight
            });

        // fade out read-more
        $p.fadeOut();

        // prevent jump-down
        return false;

    });

});

global.dropdown = function dropdown() {
    var x, i, j, selElmnt, a, b, c;
    /* Look for any elements with the class "custom-select": */
    x = document.getElementsByClassName("custom-select");
    for (i = 0; i < x.length; i++) {
        selElmnt = x[i].getElementsByTagName("select")[0];
        /* For each element, create a new DIV that will act as the selected item: */
        a = document.createElement("DIV");
        a.setAttribute("class", "select-selected");
        a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
        x[i].appendChild(a);
        /* For each element, create a new DIV that will contain the option list: */
        b = document.createElement("DIV");
        b.setAttribute("class", "select-items select-hide");
        for (j = 1; j < selElmnt.length; j++) {
            /* For each option in the original select element,
            create a new DIV that will act as an option item: */
            c = document.createElement("DIV");
            c.innerHTML = selElmnt.options[j].innerHTML;
            c.addEventListener("click", function (e) {
                /* When an item is clicked, update the original select box,
                and the selected item: */
                var y, i, k, s, h;
                s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                id = s.getAttribute('id');
                h = this.parentNode.previousSibling;
                for (i = 0; i < s.length; i++) {
                    if (s.options[i].innerHTML == this.innerHTML) {
                        s.selectedIndex = i;
                        h.innerHTML = this.innerHTML;
                        y = this.parentNode.getElementsByClassName("same-as-selected");
                        for (k = 0; k < y.length; k++) {
                            y[k].removeAttribute("class");
                        }
                        this.setAttribute("class", "same-as-selected");
                        break;
                    }
                }
                h.click();
                $("#" + id).trigger("change");
            });
            b.appendChild(c);
        }
        x[i].appendChild(b);
        a.addEventListener("click", function (e) {
            /* When the select box is clicked, close any other select boxes,
            and open/close the current select box: */
            e.stopPropagation();
            closeAllSelect(this);
            this.nextSibling.classList.toggle("select-hide");
            this.classList.toggle("select-arrow-active");
        });
    }


}

function closeAllSelect(elmnt) {
    /* A function that will close all select boxes in the document,
    except the current select box: */
    var x, y, i, arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    for (i = 0; i < y.length; i++) {
        if (elmnt == y[i]) {
            arrNo.push(i)
        } else {
            y[i].classList.remove("select-arrow-active");
        }
    }
    for (i = 0; i < x.length; i++) {
        if (arrNo.indexOf(i)) {
            x[i].classList.add("select-hide");
        }
    }
}

/* If the user clicks anywhere outside the select box,
then close all select boxes: */
document.addEventListener("click", closeAllSelect);


global.s_encode = function s_encode(str) {
    if (str != undefined) {
        str = str.replace(/-/g, "-ds-");
        str = str.replace(/ /g, "-");
        str = str.replace(/\//g, "-fs-");
        str = str.replace(/&/g, "-and-");
        str = str.replace(/\./g, "-dot-");
        str = str.replace(/,/g, "-c-");

        return str.trim();
    }
}

global.s_decode = function s_decode(str) {
    if (str != undefined) {
        str = str.replace(/-/g, " ");
        str = str.replace(/-fs-/g, "/");
        str = str.replace(/-and-/g, "&");
        str = str.replace(/-dot-/g, ".");
        str = str.replace(/-c-/g, ",");
        str = str.replace(/-ds-/g, "-");
        return str.trim();
    }
}

global.isInt = function isInt(n) {
    return n % 1 === 0;
}


global.addToCart = function addToCart(values, url) {

    $.ajax({
        url: url,
        type: "get",
        cache: false,
        data: values,
        dataType: "html",
        success: function (data) {
            trackAddToCart(values)
            increaseCartCounter();
            window.location.href = URL_SHOPPING_CART;
        }
    });

}

global.saveVehicle = function saveVehicle(year, make, model, target) {
    //window.location.href = target;
    var url = "/catalog/api/v1/vehicle/save/" + year + "/" + s_encode(make) + "/" + s_encode(model);
    $.ajax({
        url: url,
        type: "get",
        cache: false,
        dataType: "html",
        success: function (data) {
            window.location.href = target;
        }
    });


}

function increaseCartCounter() {
    currentCount = parseInt($("#cart-count").val());
    $("#cart-counter").text(currentCount + 1);
    $("#cart-counter-h").text(currentCount + 1);
}

global.trackAddToCart = function trackAddToCart(product) {
    trackingGa4.addToCart(product);

}

global.trackRemoveFromCart = function trackRemoveFromCart(product) {
    trackingGa4.removeFromCart(product);

}


global.trackVehicleSelection = function trackVehicleSelection(name, value) {
    /* if (typeof ga === "function") {
         ga('send', {
             hitType: 'event',
             eventCategory: 'Select ' + name,
             eventAction: 'click',
             eventLabel: value
         });
     }*/

}

global.trackVehicleDropdown = function trackVehicleDropdown(year, make, model) {

    var vehicle = year + " " + make + " " + model;
    /*if (typeof ga === "function") {
        // Identify the promotion that was clicked.
        ga('ec:addPromo', {
            'id': 'vehicle_dropdown',
            'name': vehicle,
            'creative': 'vehicle_dropdown_v1',
            'position': 'under_header'
        });

        // Send the promo_click action with an event.
        ga('ec:setAction', 'promo_click');
        ga('send', 'event', 'Vehicle Dropdown', 'click', vehicle)
    }*/
}

// track when a user clicks on part name on the part name page
$('body').on("click", ".product-page-link", function () {
    var category = $(this).data().category;
    trackPartNameSelection($(this).text(), category);
});


global.trackPartNameSelection = function trackPartNameSelection(partName, category) {
    /*  ga('ec:addProduct', {               // Provide product details in a productFieldObject.
          'id': partName,                 // Product ID (string).
          'name': partName,               // Product name (string).
          'category': category
      });

      ga('ec:setAction', 'click', {       // click action.
          'list': 'Part Name List'          // Product list (string).
      });*/

}

global.trackProductSelection = function trackProductSelection(product) {
    /* ga('ec:addProduct', {
         'id': product.sku,
         'name': product.name,
         'category': product.category,
         'brand': product.brand,
         'variant': product.title,
         'price': product.price,
     });
     ga('ec:setAction', 'click', {list: 'Product Name List'});

     // Send click with an event
     ga('send', 'event', 'UX', 'click', 'Product Name List');*/
}


global.showLoading = function showLoading(parentCont) {
    $.LoadingOverlay("show");
    if (parentCont === undefined) {
        //$(".content").faLoading();

    } else {
        //$(parentCont).faLoading();
        $.LoadingOverlay("show");
    }
}

global.clearLoading = function clearLoading(parentCont) {
    $.LoadingOverlay("hide");
    if (parentCont === undefined) {

        //$(".content").faLoadingStop();
    } else {
        $.LoadingOverlay("hide");
        //$(parentCont).faLoadingStop();
    }

}

global.showModalBackground = function showModalBackground() {
    $(".modal-background").addClass("is-active");
}

global.hideModalBackground = function hideModalBackground() {
    $(".modal-background").removeClass("is-active");
}
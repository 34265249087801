$(document).ready(function () {

    // perform a final check and gray out the submit button when submitted
    // then, hand over validation control to google captcha
    $('.recaptcha-protected').submit(function () {
        event.preventDefault(); //prevent form submit before captcha is completed
        $('.send-button').prop('disabled', true);
        $('.send-button').html('Please Wait...');
        grecaptcha.execute();

    });

});

global.onSubmitRecaptcha = function onSubmitRecaptcha(token) {
    $(".recaptcha-protected").submit();
}

/*

(function() {
    document.getElementById("my-form").addEventListener("submit", function(event) {
        console.log('form submitted.');
        if (!grecaptcha.getResponse()) {
            console.log('captcha not yet completed.');

            event.preventDefault(); //prevent form submit
            grecaptcha.execute();
        } else {
            console.log('form really submitted.');
        }
      });
  })();


 */
$(function()
{
	$.fancybox.defaults.infobar = false;
	$.fancybox.defaults.animationEffect = 'fade';
	$.fancybox.defaults.buttons = ['close'];

	
	$('#burger').on('click', function()
	{
		$('#mobile-nav').addClass('mobile-nav_active');
		$(document.body).addClass('noscroll');
		
	});
	$('#mobile-nav').on('click', '.mobile-nav__close', function()
	{
		$('#mobile-nav').removeClass('mobile-nav_active');
		$(document.body).removeClass('noscroll');
	});
	
	
	$('#account-nav').on('click', '.account-nav__button', function()
	{
		$(this).next().addClass('account-nav__dropdown_active');
	});
	$(document).mouseup(function(e) 
	{
		var container = $('#account-nav');
		
		if (!container.is(e.target) && container.has(e.target).length === 0) 
		{
			$('.account-nav__dropdown').removeClass('account-nav__dropdown_active');
		}
	});
	
	
	$('.filter__item').on('click', 'label', function()
	{
		$(this).parent().toggleClass('filter__item_active');

	});
	$('.filter__item_more').on('click', 'a', function()
	{
		$(this).parent().toggleClass('filter__item_more_active').prev().slideToggle(250);
		return false;
	});
	
	
	$('.product-line__wishlist').on('click', function()
	{
		$(this).toggleClass('product-line__wishlist_active');
		$('.account-nav__button').addClass('account-nav__button_dot');


	});
	$('.product-summary__wishlist').on('click', function()
	{
		$(this).toggleClass('product-summary__wishlist_active');
		$('.account-nav__button').addClass('account-nav__button_dot');

	});
	
	
	$('#product-thumbs').on('click', '.product-thumbs__item', function()
	{
		var ths = $(this);
		if( ths.hasClass('product-thumbs__item_active') )
		{
			return false;
		}
		
		$('.product-thumbs__item_active').removeClass('product-thumbs__item_active');
		var index = ths.attr("data-index");
		//$('.product-gallery__item').eq(ths.parent().index()).addClass('product-gallery__item_active').siblings().removeClass('product-gallery__item_active');
		$('.product-gallery__item').eq(index).addClass('product-gallery__item_active').siblings().removeClass('product-gallery__item_active');
		ths.addClass('product-thumbs__item_active');
	});
	$('.product-details__more').on('click', 'a', function()
	{
		$(this).parent().prev().css('max-height', 'none');
		$(this).remove();
		return false;
	});
	
	
	$('#search-headline').on('click', '.search-headline__edit', function()
	{
		var inpt = $('#search-headline .search-headline__input');
		var inptVl = inpt.val();
		inpt[0].focus();
		inpt.val('').val(inptVl);
		$('#search-headline').addClass('search-headline_active');
	});
	
	
	$('.promises__tabs').on('click', 'a', function()
	{
		$(this).addClass('active').siblings().removeClass('active');
		$($(this).attr('href')).addClass('promises__item_active').siblings('.promises__item').removeClass('promises__item_active');
		return false;
	});
	
	
	$('[data-popup]').on('click', function()
	{
		$($(this).data('popup')).addClass('popup_active');
		$(document.body).addClass('noscroll')
		return false;
	});
	$('.popup__over, .popup__close').on('click', function()
	{
		$(this).closest('.popup').removeClass('popup_active');
		$(document.body).removeClass('noscroll')
		return false;
	});
	
	
	
	$('.mobile-carousel').each(function()
	{
		var $carousel = $(this);
		if( $(window).width() > 767 )
		{
			if( $carousel.hasClass('slick-initialized') )
			{
				$carousel.slick('unslick');
			}
		}
		else
		{
			if( !$carousel.hasClass('slick-initialized') )
			{
				$carousel.slick({
					rows: 0,
					slidesToShow: 1,
					slidesToScroll: 1,
					swipeToSlide: true,
					mobileFirst: true,
					variableWidth: true,
					arrows: false
				});
				$carousel.on('afterChange', function(event, slick, currentSlide)
				{
					$carousel.next().find('.counter').text(1 + currentSlide);
					$carousel.next().parent().find('.counter').first().text(1 + currentSlide);
				});
			}
		}
	});

	$('.carousel-noloop').each(function()
	{
		var $carousel = $(this);

		if( !$carousel.hasClass('slick-initialized') )
		{
			$carousel.slick({
				infinite: false,
				rows: 0,
				slidesToShow: 1,
				slidesToScroll: 1,
				swipeToSlide: true,
				mobileFirst: true,
				variableWidth: true,
				arrows: false
			});
			$carousel.on('afterChange', function(event, slick, currentSlide)
			{
				$carousel.next().find('.counter').text(1 + currentSlide);
				$carousel.next().parent().find('.counter').first().text(1 + currentSlide);
			});
		}
	});


	$('.mobile-carousel-noloop').each(function()
	{
		var $carousel = $(this);
		if( $(window).width() > 767 )
		{
			if( $carousel.hasClass('slick-initialized') )
			{
				$carousel.slick('unslick');
			}
		}
		else
		{
			if( !$carousel.hasClass('slick-initialized') )
			{
				$carousel.slick({
					infinite: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					swipeToSlide: true,
					mobileFirst: true,
					variableWidth: true,
					arrows: false
				});
				$carousel.on('afterChange', function(event, slick, currentSlide)
				{
					$carousel.next().find('.counter').text(1 + currentSlide);
				});
			}
		}
	});

	$(window).on('resize orientationchange', function()
	{
		$('.mobile-carousel').each(function()
		{
			var $carousel = $(this);
			if( $(window).width() > 767 )
			{
				if( $carousel.hasClass('slick-initialized') )
				{
					$carousel.slick('unslick');
				}
			}
			else
			{
				if( !$carousel.hasClass('slick-initialized') )
				{
					$carousel.slick({
						slidesToShow: 1,
						slidesToScroll: 1,
						swipeToSlide: true,
						mobileFirst: true,
						variableWidth: true,
						arrows: false
					});
					$carousel.on('afterChange', function(event, slick, currentSlide)
					{
						$carousel.next().find('.counter').text(1 + currentSlide);
					});
				}
			}
		});

		$('.mobile-carousel-noloop').each(function()
		{
			var $carousel = $(this);
			if( $(window).width() > 767 )
			{
				if( $carousel.hasClass('slick-initialized') )
				{
					$carousel.slick('unslick');
				}
			}
			else
			{
				if( !$carousel.hasClass('slick-initialized') )
				{
					$carousel.slick({
						infinite: false,
						slidesToShow: 1,
						slidesToScroll: 1,
						swipeToSlide: true,
						mobileFirst: true,
						variableWidth: true,
						arrows: false
					});
					$carousel.on('afterChange', function(event, slick, currentSlide)
					{
						$carousel.next().find('.counter').text(1 + currentSlide);
					});
				}
			}
		});
	});	
});